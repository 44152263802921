<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Offers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Offers"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.offerDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Offer</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{
                    name: 'module-melrose-properties-offers-archived',
                  }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Offers</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="offers"
        class="mt-4"
        show-expand
        no-data-text="No Offers found"
      >
        <template v-slot:item.property="{ item }">
          <router-link
            :to="{
              name: 'module-melrose-properties-individual',
              params: { propertyId: item.property.id },
            }"
            v-html="item.property.address.short"
          ></router-link>
        </template>
        <template v-slot:item.customer="{ item }">
          {{ item.enquiry.customer.full_name }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.offerDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="$refs.deleteOfferDialog.openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pt-4 pb-4">
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Notes</th>
                  <td v-html="item.notes" class="pt-4 pb-4"></td>
                </tr>
                <tr v-if="item.solicitor">
                  <th>Solicitor</th>
                  <td class="pt-4 pb-4">
                    <div>{{ item.solicitor.name }}</div>
                    <div>
                      <strong>Phone:</strong> {{ item.solicitor.phone }}
                    </div>
                    <div>
                      <strong>Email:</strong> {{ item.solicitor.email }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <offer-dialog ref="offerDialog" />
    <delete-offer-dialog ref="deleteOfferDialog" />
  </div>
</template>

<script>
import OfferDialog from "./components/OfferDialog.vue";
import DeleteOfferDialog from "./components/DeleteOfferDialog.vue";

export default {
  components: {
    OfferDialog,
    DeleteOfferDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          exact: true,
          to: { name: "module-melrose-properties-all" },
        },
        {
          text: "Offers",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Property", value: "property" },
        { text: "Customer", value: "customer" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  computed: {
    offers() {
      let offers = this.$store.getters["melrose/propertiesStore/offers"];

      if (this.searchTerm !== "") {
        offers = offers.filter((o) => {
          const property = o.address.short.toLowerCase();
          const customer = o.customer.full_name.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return property.includes(searchTerm) || customer.includes(searchTerm);
        });
      }

      return offers;
    },
  },
};
</script>
